import React, { useEffect } from "react";
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import { getValueByKey } from "../../utils/helperFn";
import FeaturedImage from '../../assets/image/rebrand/png/youfibre-featured-img.png';
import { Location } from '@reach/router';
import FacebookPixel from './../../utils/facebook-pixel';
import TSAPI from './../../api';
import { hotjar } from 'react-hotjar';
import { v4 as uuidv4 } from 'uuid';

// featuredImageUrl 
const Seo = ({ page, featuredImageUrl }) => {
    // Todo add variable page.
    const data = useStaticQuery(graphql`
        query myQuery {
            allStrapiSeo(sort: {fields: strapiId, order: ASC}) {
                nodes {
                    meta_title
                    meta_description
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    page    
                }
            }
            allStrapiSeoNew(sort: {fields: strapiId, order: ASC}) {
                nodes {
                    meta_title
                    meta_description
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    page    
                }
            }
        }      
    `);

    useEffect(() => {
        if (typeof window !== 'undefined' && window) {
            window.onerror = () => {
                return true;
            };
        }
        let s = null
        s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `(function (f, o, r, c, e, _2, _4) {
            f.Force24Object = e, f[e] = f[e] || function () {
                f[e].q = f[e].q || [], f[e].q.push(arguments)
            }, f[e].l = 1 * new Date, _2 = o.createElement(r),
            _4 = o.getElementsByTagName(r)[0], _2.async = !0, _2.src = c;
        
            _2.onload = function () {
                // Script loaded successfully, you can now execute its functions
                f24('config', 'set_tracking_id', '554e0046-10f7-4e21-964c-ade5e918c468');
                f24('config', 'set_client_id', '6fb38e48-598b-438a-8e21-74af83f352dc');
            };
        
            _2.onerror = function () {
                // Handle the error if the script fails to load
                console.error("Error loading external script:", c);
                // You can prevent further script execution here if needed
            };
        
            _4.parentNode.insertBefore(_2, _4);
        })(window, document, "script", "https://static.websites.data-crypt.com/scripts/activity/v3/inject-v3.min.js", "f24");
        `;
        document.head.appendChild(s);
        return () => {
            document.head.removeChild(s);
        };
    }, [])

    useEffect(() => {
        hotjar.initialize(3638122, 6);
        hotjar.identify(uuidv4(), { userProperty: 'value' });
    }, []);

    const seoData = getProperty(data, 'allStrapiSeo');
    const seo1Data = getProperty(data, 'allStrapiSeoNew');

    const totalData = seoData.nodes.concat(seo1Data.nodes)

    const sd = getValueByKey(totalData, 'page', page);

    if (sd !== null && sd !== undefined) {
        return (
            <FacebookPixel>
                <Location>
                    {({ location }) => {
                        return (
                            <Helmet>
                                <title>{sd && sd.meta_title}</title>
                                <meta name="title" content={sd && sd.meta_title} />
                                <meta name="description" content={sd && sd.meta_description} />
                                <meta property="og:title" content={sd && sd.meta_title} />
                                <meta property="og:description" content={sd && sd.meta_description} />
                                <meta property="og:image" content={
                                    (featuredImageUrl !== undefined && featuredImageUrl !== null) ?
                                        featuredImageUrl : (
                                            (sd.featured_image !== null && sd.featured_image !== undefined) ?
                                                sd.featured_image.childImageSharp.fluid.src : FeaturedImage
                                        )
                                } />
                                <meta property="og:url" content={location.href} />
                                <meta name="twitter:title" content={sd && sd.meta_title} />
                                <meta name="twitter:description" content={sd && sd.meta_description} />
                                <meta name="twitter:image" content={
                                    (featuredImageUrl !== undefined && featuredImageUrl !== null) ?
                                        featuredImageUrl : (
                                            (sd.featured_image !== null && sd.featured_image !== undefined) ?
                                                sd.featured_image.childImageSharp.fluid.src : FeaturedImage
                                        )
                                } />
                                <meta name="twitter:card" content="summary_large_image" />
                                {handleNoIndex(location)}
                            </Helmet>
                        );
                    }}

                </Location>
            </FacebookPixel>

        );
    } else {
        // In this case SEO info for this page is not defined in SEO component
        return (
            <FacebookPixel>


                <Location>
                    {({ location }) => {
                        return (
                            <Helmet>
                                <title>YouFibre</title>
                                <meta name="title" content="YouFibre" />
                                <meta name="description" content="YouFibre" />
                                <meta property="og:title" content="YouFibre" />
                                <meta property="og:description" content="YouFibre" />
                                <meta property="og:image" content={(featuredImageUrl !== undefined && featuredImageUrl !== null) ? featuredImageUrl : FeaturedImage} />
                                <meta property="og:url" content={location.href} />
                                <meta name="twitter:title" content="YouFibre" />
                                <meta name="twitter:description" content="YouFibre" />
                                <meta name="twitter:image" content={(featuredImageUrl !== undefined && featuredImageUrl !== null) ? featuredImageUrl : FeaturedImage} />
                                <meta name="twitter:card" content="summary_large_image" />
                                {handleNoIndex(location)}
                            </Helmet>
                        );
                    }}
                </Location>
            </FacebookPixel>
        );
    }
};

const handleNoIndex = (location) => {
    const noIndexPages = [
        'psearchxmas22',
        'wakefieldxmas',
        'gravesendxmas',
        'bostonxmas',
        'e-xmas22',
        't-xmas22',
        'salesxmas22',
        'psocialxmas22',
        'installerdm',
        'tci2',
        'kentcricket',
        'spennymoorfc',
        'gforce',
        'tci',
        'janlaunch23-e',
        'register',
        'janlaunch23-ps',
        'request-sent',
        'success',
        'payment',
        'launch',
        'sales',
        'december-2022',
        'janlaunch23-t',
        'janlaunch23-fb',
        'price-book',
        'janlaunchdm',
        'december-500',
        'darlingtonfc',
        '25k-customer',
        'spotify',
        'barrow',
        'barry',
        'boston',
        'bridgend',
        'cheltenham',
        'durham',
        'downend',
        'gravesend',
        'houghton',
        'lancaster',
        'falkirk',
        'sefton-park',
        'neath',
        'spennymoor',
        'oxford',
        'purfleet',
        'spalding',
        'darlington',
        'kings-lynn',
        'peterlee',
        'wakefield',
        'stockton',
        'maidstone',
        'ashford',
        'aylesbury',
        'bamber-bridge',
        'burntwood',
        'canterbury',
        'carlisle',
        'dalton',
        'doddington',
        'dover',
        'east-kilbride',
        'folkestone',
        'frome',
        'grantham',
        'guildford',
        'hamilton',
        'hengoed',
        'horwich',
        'huntingdon',
        'irvine-bourtree',
        'kilmarnock',
        'livingston',
        'derry',
        'macclesfield',
        'mansfield',
        'morriston',
        'newport',
        'oldham',
        'pontefract',
        'st-neots',
        'stroud',
        'sutton-in-ashfield',
        'tewkesbury',
        'trimdon',
        'trowbridge',
        'tunbridge-wells',
        'wednesbury',
        'wisbech'
    ];

    if (location.pathname.charAt(location.pathname.length - 1) === '/') {
        location.pathname = location.pathname.substring(0, location.pathname.length - 1)
    }

    const splitLocation = location.pathname.split('/')
    const slugName = splitLocation[(splitLocation.length - 1)]

    if ((location && noIndexPages.includes(slugName))) {
        return <meta name="robots" content="noindex" />
    } else {
        return
    }
}

export default Seo;
